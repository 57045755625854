<script>
import CheckboxOption from "@/components/poll/CheckboxOption.vue";

export default {
  name: "CheckboxOptions",
  components: {CheckboxOption},
  props: ['options', 'disabled']
}
</script>

<template>
  <div>
    <CheckboxOption
      v-for="(option, index) in options" :key="index"
      v-model="options[index].value"
      :disabled="disabled"
    >
      {{ option.text }}
    </CheckboxOption>
  </div>
</template>

<style scoped lang="scss">

</style>