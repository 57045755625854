<script>

export default {
  name: "DropdownOptionsEditor",
  props: ['options'],
  emits: ['update:options'],
  data() {
    return {
      limits: {
        MIN_OPTIONS: 2, MAX_OPTIONS: 10
      },
      index: 2,
    }
  },
  methods: {
    addOption() {
      if (this.options.length >= this.limits.MAX_OPTIONS)
        return

      this.options.push({text: '', value: this.index++})
      this.$emit('update:options', this.options)
    },
    removeOption(option) {
      if (this.options.length <= this.limits.MIN_OPTIONS)
        return

      let index = this.options.indexOf(option)
      this.options.splice(index, 1)
      this.$emit('update:options', this.options)
    },
  }
}
</script>

<template>
  <div>
    <div
      class="option"
      v-for="(option, index) in options"
    >
      <div class="option__icon">
<!--        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path d="M5.75 4C4.7835 4 4 4.7835 4 5.75V10.25C4 11.2165 4.7835 12 5.75 12H10.25C11.2165 12 12 11.2165 12 10.25V5.75C12 4.7835 11.2165 4 10.25 4H5.75Z"/>-->
<!--        </svg>-->
        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 13C10.5523 13 11 12.5523 11 12C11 11.4477 10.5523 11 10 11C9.44772 11 9 11.4477 9 12C9 12.5523 9.44772 13 10 13Z"/>
          <path d="M6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55229 11 6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13Z"/>
          <path d="M7 8C7 8.55229 6.55228 9 6 9C5.44772 9 5 8.55229 5 8C5 7.44772 5.44772 7 6 7C6.55228 7 7 7.44772 7 8Z"/>
          <path d="M10 9C10.5523 9 11 8.55228 11 8C11 7.44772 10.5523 7 10 7C9.44772 7 9 7.44772 9 8C9 8.55228 9.44772 9 10 9Z"/>
          <path d="M11 4C11 4.55229 10.5523 5 10 5C9.44772 5 9 4.55229 9 4C9 3.44772 9.44772 3 10 3C10.5523 3 11 3.44772 11 4Z"/>
          <path d="M6 5C6.55228 5 7 4.55228 7 4C7 3.44772 6.55228 3 6 3C5.44772 3 5 3.44772 5 4C5 4.55228 5.44772 5 6 5Z"/>
        </svg>
      </div>
      <TransparentInput
        :placeholder="`Ответ ${index + 1}`"
        v-model="options[index].text"
        maxlength="64"
      />
      <TransparentButton
        title="Удалить"
        v-if="options.length > limits.MIN_OPTIONS"
        @click="() => removeOption(option)"
      >
        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.5 1.75C6.5 1.61193 6.61193 1.5 6.75 1.5H9.25C9.38807 1.5 9.5 1.61193 9.5 1.75V3H6.5V1.75ZM11 1.75V3H13.25C13.6642 3 14 3.33579 14 3.75C14 4.16421 13.6642 4.5 13.25 4.5H2.75C2.33579 4.5 2 4.16421 2 3.75C2 3.33579 2.33579 3 2.75 3H5V1.75C5 0.783502 5.7835 0 6.75 0H9.25C10.2165 0 11 0.783502 11 1.75ZM4.49627 6.67537C4.45506 6.26321 4.08753 5.9625 3.67537 6.00372C3.26321 6.04493 2.9625 6.41247 3.00372 6.82462L3.66367 13.4241C3.75313 14.3187 4.50592 15 5.40498 15H10.595C11.4941 15 12.2469 14.3187 12.3363 13.4241L12.9963 6.82462C13.0375 6.41247 12.7368 6.04493 12.3246 6.00372C11.9125 5.9625 11.5449 6.26321 11.5037 6.67537L10.8438 13.2749C10.831 13.4027 10.7234 13.5 10.595 13.5H5.40498C5.27655 13.5 5.169 13.4027 5.15622 13.2749L4.49627 6.67537Z"/>
        </svg>
      </TransparentButton>
    </div>

    <div class="buttons">
      <LaconicButton
        text="Добавить ответ"
        @click="addOption"
      >
        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.75 2C7.94891 2 8.13968 2.07902 8.28033 2.21967C8.42098 2.36032 8.5 2.55109 8.5 2.75V7H12.75C12.9489 7 13.1397 7.07902 13.2803 7.21967C13.421 7.36032 13.5 7.55109 13.5 7.75C13.5 7.94891 13.421 8.13968 13.2803 8.28033C13.1397 8.42098 12.9489 8.5 12.75 8.5H8.5V12.75C8.5 12.9489 8.42098 13.1397 8.28033 13.2803C8.13968 13.421 7.94891 13.5 7.75 13.5C7.55109 13.5 7.36032 13.421 7.21967 13.2803C7.07902 13.1397 7 12.9489 7 12.75V8.5H2.75C2.55109 8.5 2.36032 8.42098 2.21967 8.28033C2.07902 8.13968 2 7.94891 2 7.75C2 7.55109 2.07902 7.36032 2.21967 7.21967C2.36032 7.07902 2.55109 7 2.75 7H7V2.75C7 2.55109 7.07902 2.36032 7.21967 2.21967C7.36032 2.07902 7.55109 2 7.75 2Z"/>
        </svg>
      </LaconicButton>
    </div>
  </div>
</template>

<style scoped lang="scss">

.option{
  width: 100%; height: 2.75rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 1rem;
  font-weight: 400;
  color: var(--color-text-default);

  &__icon {
    min-width: 1.25rem; height: 1.25rem;
    padding: 0.125rem; margin: 0.75rem;
    border-radius: 0.25rem;
    background-color: var(--color-mute);
    line-height: 1rem;

    svg {
      width: 1rem; height: 1rem;
      fill: var(--color-mute);
    }
  }

  &:deep(button){
    display: none;
  }
  &:hover:deep(button){
    display: block;
  }
}
.buttons{
  width: 100%; height: 2.75rem;

  display: flex;
  flex-direction: row;
  gap: 1rem;

  margin-top: 1rem;
}
</style>