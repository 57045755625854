<script>

export default {
  name: "Page",
  props: ['title']
}
</script>

<template>
  <div class="page">
    <div class="page__header">
      <div class="page__title">
        {{ title }}
      </div>
    </div>

    <div class="page__body">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">

.page{
  width: 100%;
  border-radius: 1rem;
  background-color: var(--color-page);
  padding: 1.5rem 2rem;

  &__header{
    height: 2.75rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    .page__title{
      font-size: 1.5rem;
      font-weight: 500;
      color: var(--color-text-default);
      margin-right: auto;
    }
  }

  &__body{
    width: 100%;
    padding: 1.5rem 0;

    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

@media only screen and (orientation: portrait) and (max-device-width: 414px){
  .page{
    padding: 1.25rem 0.75rem;
  }
}
</style>