<script>

export default {
  name: "InputNumberEditor",
  props: ['structure'],
  data() {
    return {
      withRange: false
    }
  },
  watch: {
    withRange() {
      if (this.withRange === false) {
        this.structure.min = ''
        this.structure.max = ''
      }
    }
  },
  beforeMount() {
    if (this.structure.min === -10000000)
      this.structure.min = ''
    if (this.structure.max === 10000000)
      this.structure.max = ''
    this.withRange = +this.structure.min || +this.structure.max
  }
}
</script>

<template>
  <div>
    <Input
      placeholder="Введите число"
      :disabled="true"
    />
    <div class="buttons">
      <Toggle
        v-model="withRange"
        text="Диапазон чисел"
        style="margin-right: 1rem"
      />
      <div class="buttons__group" v-if="withRange">
        От
        <InputNumber
          v-model="structure.min"
          placeholder="0"
          :min="-10000000"
          :max="structure.max || 9999999"
          style="width: 5rem; padding: 0; border: none"
        />
        до
        <InputNumber
          v-model="structure.max"
          placeholder="100"
          :min="structure.min || -9999999"
          :max="10000000"
          style="width: 5rem; padding: 0; border: none"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.buttons{
  width: 100%; height: 2.75rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  margin-top: 1rem;

  &__group{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
}
</style>