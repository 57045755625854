import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue"
import RedButton from "@/components/ui/buttons/RedButton.vue"
import Input from "@/components/ui/inputs/Input.vue"
import SecondaryButton from "@/components/ui/buttons/SecondaryButton.vue"
import Selector from "@/components/ui/inputs/Selector.vue"
import TransparentInput from "@/components/ui/inputs/TransparentInput.vue"
import TransparentButton from "@/components/ui/buttons/TransparentButton.vue"
import Checkbox from "@/components/ui/inputs/Toggle.vue"
import LaconicButton from "@/components/ui/buttons/LaconicButton.vue"
import BigBlueButton from "@/components/ui/buttons/BigBlueButton.vue"
import Steps from "@/components/ui/Steps.vue"
import Card from "@/components/ui/Card.vue"
import InputNumber from "@/components/ui/inputs/InputNumber.vue"
import Warning from "@/components/ui/Warning.vue"
import Loading from "@/components/ui/Loading.vue"
import Info from "@/components/ui/Info.vue"
import InputArea from "@/components/ui/inputs/InputArea.vue"
import Range from "@/components/ui/inputs/Range.vue"
import Toggle from "@/components/ui/inputs/Toggle.vue"

export default [
    Card,
    PrimaryButton, RedButton, SecondaryButton, TransparentButton, LaconicButton, BigBlueButton,
    Input, Selector, TransparentInput, Checkbox, InputNumber, InputArea, Range, Toggle,
    Steps,
    Warning, Info,
    Loading
]