<script>
import Question from "@/components/poll/Question.vue"
import RadioOption from "@/components/poll/RadioOption.vue"
import CheckboxOption from "@/components/poll/CheckboxOption.vue"

import request from "@/request"
import {notify} from "@kyvg/vue3-notification"

export default {
  name: "DemoView",
  components: {Question, CheckboxOption, RadioOption},
  data() {
    return {
      loading: true,
      poll: {
        info: {
          title: ''
        },
        pages: [
          {
            title: '',
            questions: []
          }
        ]
      },
    }
  },
  methods: {
    async getInfo() {
      const response = await request('polls/getInfo', {id: this.$route.params.id})
      if (response) this.poll.info = response
    },
    async getPages() {
      const response = await request('polls/getPages', {id: this.$route.params.id})
      if (response) {
        this.poll.pages = response
        this.loading = false
      }
    },
    notify
  },
  beforeMount() {
    this.getInfo()
    this.getPages()
  }
}
</script>

<template>
  <div class="demo">
    <h1 class="demo__title">{{ poll.info.title }}</h1>
    <div class="demo__header">
      <Steps
        :steps="poll.pages.map((page, index) => ({text: page.title, to: `./${index}`}))"
        :active-index="+$route.params.page"
      />
      <LaconicButton
        text="Закрыть демонстрацию"
        style="margin-left: auto"
        @click="$router.push(`/manage/${$route.params.id}/edit`)"
      >
        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.142598 2.31017C0.385538 1.97468 0.854448 1.89966 1.18994 2.1426L15.6899 12.6426C16.0254 12.8855 16.1005 13.3544 15.8575 13.6899C15.6146 14.0254 15.1457 14.1005 14.8102 13.8575L12.5615 12.2292C11.3457 13.1889 9.7922 14.0001 8.00006 14.0001C6.0187 14.0001 4.32923 13.0084 3.06667 11.9225C1.79722 10.8308 0.8805 9.57698 0.42968 8.89876C0.0668461 8.35291 0.0657162 7.64896 0.429259 7.10198C0.783329 6.56925 1.4245 5.68192 2.2979 4.7969L0.310172 3.35751C-0.0253172 3.11457 -0.100343 2.64566 0.142598 2.31017ZM3.52875 5.6882C2.66555 6.5303 2.02476 7.4113 1.6785 7.93227C1.66294 7.95569 1.65707 7.97881 1.65708 7.99992C1.65709 8.02107 1.66303 8.04455 1.67888 8.0684C2.09147 8.68911 2.92065 9.81854 4.04474 10.7853C5.17571 11.7579 6.52743 12.5001 8.00006 12.5001C9.19465 12.5001 10.3095 12.0116 11.2907 11.3089L9.06202 9.69505C8.75428 9.88827 8.39019 10 8 10C6.89543 10 6 9.10458 6 8.00001C6 7.83446 6.02011 7.6736 6.05803 7.51976L3.52875 5.6882ZM8.00006 3.50005C7.48367 3.50005 6.98343 3.59079 6.50129 3.7511C6.10823 3.88179 5.68365 3.6691 5.55296 3.27604C5.42227 2.88299 5.63496 2.45841 6.02802 2.32772C6.64531 2.12247 7.30538 2.00005 8.00006 2.00005C9.98141 2.00005 11.6709 2.99172 12.9334 4.07756C14.2029 5.16932 15.1196 6.42313 15.5704 7.10135C15.9337 7.64779 15.9336 8.3524 15.5704 8.89879C15.4598 9.0652 15.3223 9.26447 15.1596 9.48627C14.9146 9.82026 14.4452 9.8924 14.1113 9.6474C13.7773 9.4024 13.7051 8.93304 13.9501 8.59905C14.0979 8.39766 14.2221 8.2175 14.3212 8.06843C14.35 8.02506 14.35 7.97502 14.3212 7.93171C13.9086 7.311 13.0795 6.18157 11.9554 5.21482C10.8244 4.24216 9.47268 3.50005 8.00006 3.50005Z"/>
        </svg>
      </LaconicButton>
    </div>

    <div class="demo__body">
      <loading v-if="loading"/>

      <Question
        v-for="(question, index) in poll.pages[$route.params.page].questions"
        v-model:question="poll.pages[$route.params.page].questions[index]"
      />

      <BigBlueButton
        text="Завершить"
        v-if="+$route.params.page === poll.pages.length - 1"
        @click="$router.push(`/manage/${$route.params.id}/edit`)"
      />
      <BigBlueButton
        text="Далее"
        v-else
        @click="$router.push(`./${+$route.params.page + 1}`)"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">

.demo{
  max-width: 56rem;
  margin: 0 auto 5rem;

  &__title{
    font-size: 2rem;
    font-weight: 600;

    margin: 1rem 0;
  }

  &__header{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }

  &__body{
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
</style>