<script>
export default {
  name: "RadioOption",
  props: ['modelValue', 'name', 'disabled'],
  emits: ['update:model-value'],
}
</script>

<template>
  <label class="radio">
    <input
      type="radio"
      :checked="modelValue"
      @click="$emit('update:model-value', $event.target.checked)"
      :name="name"
      :disabled="disabled"
    >
    <div class="radio__circle"></div>
    <slot></slot>
  </label>
</template>

<style scoped lang="scss">

.radio{
  width: 100%; height: 2.75rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 1rem;
  font-weight: 400;
  color: var(--color-text-default);
  line-height: 2.75rem;

  input {
    display: none;
  }

  &__circle {
    min-width: 1.25rem; height: 1.25rem;
    margin: 0.75rem;
    border-radius: 0.625rem;
    background-color: var(--color-mute);
  }

  &:has(input:checked) .radio__circle{
    background-color: var(--white);
    border: var(--blue) solid 0.375rem;
  }

  &:deep(button){
    display: none;
  }
  &:hover:deep(button){
    display: block;
  }
}

</style>