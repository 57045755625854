<script>

export default {
  name: "Steps",
  props: {
    steps: Array,
    activeIndex: Number,
  },
}
</script>

<template>
  <div class="steps">
    <router-link
      v-for="(step, index) in steps" :key="index"
      :to="step.locked ? '' : step.to"
      :class="{step, active: index === activeIndex, locked: step.locked}"
    >
      <div class="step__number">{{ index + 1 }}</div>
      <div class="step__text">{{ step.text }}</div>
      <svg class="step__lock" v-if="step.locked"
           viewBox="0 0 12 15" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 4V6H1.75C1.28587 6 0.84075 6.18437 0.51256 6.51256C0.18437 6.84075 0 7.28587 0 7.75V13.25C0 14.216 0.784 15 1.75 15H10.25C10.7141 15 11.1592 14.8156 11.4874 14.4874C11.8156 14.1592 12 13.7141 12 13.25V7.75C12 7.28587 11.8156 6.84075 11.4874 6.51256C11.1592 6.18437 10.7141 6 10.25 6H10V4C10 2.93913 9.5786 1.92172 8.8284 1.17157C8.0783 0.421427 7.06087 0 6 0C4.93913 0 3.92172 0.421427 3.17157 1.17157C2.42143 1.92172 2 2.93913 2 4ZM8.5 6V4C8.5 3.33696 8.2366 2.70107 7.76777 2.23223C7.29893 1.76339 6.66304 1.5 6 1.5C5.33696 1.5 4.70107 1.76339 4.23223 2.23223C3.76339 2.70107 3.5 3.33696 3.5 4V6H8.5ZM10 7.5H10.25C10.3163 7.5 10.3799 7.52634 10.4268 7.57322C10.4737 7.62011 10.5 7.6837 10.5 7.75V13.25C10.5 13.3163 10.4737 13.3799 10.4268 13.4268C10.3799 13.4737 10.3163 13.5 10.25 13.5H1.75C1.6837 13.5 1.62011 13.4737 1.57322 13.4268C1.52634 13.3799 1.5 13.3163 1.5 13.25V7.75C1.5 7.6837 1.52634 7.62011 1.57322 7.57322C1.62011 7.52634 1.6837 7.5 1.75 7.5H10Z"/>
      </svg>
    </router-link>
  </div>
</template>

<style scoped lang="scss">

.steps{
  padding: 1.5rem 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;

  .step{
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    &__number{
      width: 1.5rem; height: 1.5rem;
      background-color: var(--color-mute);
      border-radius: 50%;

      font-size: 0.875rem;
      font-weight: 500;
      color: var(--color-text-default);
      line-height: 1.5rem;
      text-align: center;

      //position: relative;
      //&::before{
      //  content: "";
      //  position: absolute;
      //  top: calc(0.75rem - 0.625px); left: -1.5rem;
      //  width: 1.5rem; height: 1.25px;
      //  background-color: var(--color-mute);
      //}
    }

    //&:first-of-type .step__number::before{
    //  display: none;
    //}

    &__text{
      font-size: 1rem;
      font-weight: 500;
      color: var(--color-text-default);
      white-space: nowrap;
    }

    &__lock{
      width: 1rem; height: 1rem;
      fill: var(--color-text-mute);
      margin-left: 0.5rem;
    }

    &.active{
      .step__number{
        color: var(--white);
        background-color: var(--blue);

        //&::before{
        //  background-color: var(--blue);
        //}
      }
      .step__text{
        color: var(--blue);
      }
    }

    &.locked{
      opacity: 0.25;
    }
  }
}

@media only screen and (orientation: portrait) and (max-device-width: 414px){
  .steps{
    flex-direction: column;
    align-items: start;
    gap: 1.25rem;
    padding-left: 1rem;
  }
}
</style>