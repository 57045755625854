<script>
import InputNumberEditor from "@/components/edit/InputNumberEditor.vue"
import RadioOptionsEditor from "@/components/edit/RadioOptionsEditor.vue"
import CheckboxOptionsEditor from "@/components/edit/CheckboxOptionsEditor.vue"
import DropdownOptionsEditor from "@/components/edit/DropdownOptionsEditor.vue"
import RangeEditor from "@/components/edit/RangeEditor.vue"

export default {
  name: "Question",
  components: {
    DropdownOptionsEditor, InputNumberEditor, RangeEditor, CheckboxOptionsEditor, RadioOptionsEditor
  },
  props: ['question'],
  emits: ['update:question', 'remove', 'copy'],
  data() {
    return {
      types: [
        {
          text: 'Короткий ответ', value: 'shortText',
          icon: require('@/assets/types/shortText.svg')
        },
        {
          text: 'Длинный ответ', value: 'longText',
          icon: require('@/assets/types/longText.svg')
        },
        {
          text: 'Число', value: 'number',
          icon: require('@/assets/types/number.svg')
        },
        { divider: true },
        {
          text: 'Один из списка', value: 'radio',
          icon: require('@/assets/types/radio.svg')
        },
        {
          text: 'Несколько из списка', value: 'checkbox',
          icon: require('@/assets/types/checkbox.svg')
        },
        {
          text: 'Выпадающий список', value: 'dropdown',
          icon: require('@/assets/types/dropdown.svg')
        },
        { divider: true },
        {
          text: 'Шкала', value: 'range',
          icon: require('@/assets/types/range.svg')
        },
        { divider: true },
        {
          text: 'Электронная почта', value: 'email',
          icon: require('@/assets/types/email.svg')
        },
        {
          text: 'Номер телефона', value: 'phone',
          icon: require('@/assets/types/phone.svg')
        },
        { divider: true },
        {
          text: 'Адрес', value: 'address',
          icon: require('@/assets/types/address.svg')
        },
        { divider: true },
        {
          text: 'Дата', value: 'date',
          icon: require('@/assets/types/date.svg')
        },
        {
          text: 'Время', value: 'time',
          icon: require('@/assets/types/time.svg')
        },
      ],
    }
  },
  watch: {
    'question.type'(newType, oldType) {
      this.updateStructure()
    }
  },
  methods: {
    updateStructure() {
      this.question.structure = {
        'shortText': {text: ''},
        'longText': {text: ''},
        'number': {min: '', max: '', number: ''},
        'radio': {options: [{text: '', value: false}, {text: '', value: false}]},
        'checkbox': {options: [{text: '', value: false}, {text: '', value: false}]},
        'dropdown': {value: 0, options: [{text: '', value: 0}, {text: '', value: 1}]},
        'range': {min: 0, max: 10, value: 5},
        'email': {email: ''},
        'phone': {phone: ''},
      }[this.question.type]
      this.$emit('update:question', this.question)
    }
  }
}
</script>

<template>
  <Card class="question">
    <div class="question__header">
      <Selector
        v-model="question.type"
        :options="types"
        style="margin-right: 1rem"
      />
      <Toggle
        v-model="question.required"
        text="Обязательный"
      />

      <TransparentButton
        @click="$emit('copy')"
        title="Сделать копию"
        style="margin-left: auto"
      >
        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 0C5.7835 0 5 0.783502 5 1.75V9.25C5 10.2165 5.7835 11 6.75 11H14.25C15.2165 11 16 10.2165 16 9.25V1.75C16 0.783502 15.2165 0 14.25 0H6.75ZM6.5 1.75C6.5 1.61193 6.61193 1.5 6.75 1.5H14.25C14.3881 1.5 14.5 1.61193 14.5 1.75V9.25C14.5 9.38807 14.3881 9.5 14.25 9.5H6.75C6.61193 9.5 6.5 9.38807 6.5 9.25V1.75Z"/>
          <path d="M1.75 5C0.783502 5 0 5.7835 0 6.75V14.25C0 15.2165 0.783502 16 1.75 16H9.25C10.2165 16 11 15.2165 11 14.25V12.75C11 12.3358 10.6642 12 10.25 12C9.83579 12 9.5 12.3358 9.5 12.75V14.25C9.5 14.3881 9.38807 14.5 9.25 14.5H1.75C1.61193 14.5 1.5 14.3881 1.5 14.25V6.75C1.5 6.61193 1.61193 6.5 1.75 6.5H3.25C3.66421 6.5 4 6.16421 4 5.75C4 5.33579 3.66421 5 3.25 5H1.75Z"/>
        </svg>
      </TransparentButton>
      <TransparentButton
        @click="$emit('remove')"
        title="Удалить"
      >
        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.5 1.75C6.5 1.61193 6.61193 1.5 6.75 1.5H9.25C9.38807 1.5 9.5 1.61193 9.5 1.75V3H6.5V1.75ZM11 1.75V3H13.25C13.6642 3 14 3.33579 14 3.75C14 4.16421 13.6642 4.5 13.25 4.5H2.75C2.33579 4.5 2 4.16421 2 3.75C2 3.33579 2.33579 3 2.75 3H5V1.75C5 0.783502 5.7835 0 6.75 0H9.25C10.2165 0 11 0.783502 11 1.75ZM4.49627 6.67537C4.45506 6.26321 4.08753 5.9625 3.67537 6.00372C3.26321 6.04493 2.9625 6.41247 3.00372 6.82462L3.66367 13.4241C3.75313 14.3187 4.50592 15 5.40498 15H10.595C11.4941 15 12.2469 14.3187 12.3363 13.4241L12.9963 6.82462C13.0375 6.41247 12.7368 6.04493 12.3246 6.00372C11.9125 5.9625 11.5449 6.26321 11.5037 6.67537L10.8438 13.2749C10.831 13.4027 10.7234 13.5 10.595 13.5H5.40498C5.27655 13.5 5.169 13.4027 5.15622 13.2749L4.49627 6.67537Z"/>
        </svg>
      </TransparentButton>
    </div>

    <TransparentInput
      placeholder="Введите текст вопроса"
      v-model="question.text"
      style="margin: 0.75rem 0; font-size: 1.125rem; font-weight: 600"
    />

    <div class="question__body">
      <Input
        v-if="question.type === 'shortText'"
        disabled placeholder="Введите ответ"
      />
      <InputArea
        v-if="question.type === 'longText'"
        disabled placeholder="Введите ответ"
      />
      <InputNumberEditor
        v-if="question.type === 'number'"
        v-model:structure="question.structure"
      />

      <RadioOptionsEditor
        v-if="question.type === 'radio'"
        v-model:options="question.structure.options"
      />
      <CheckboxOptionsEditor
        v-if="question.type === 'checkbox'"
        v-model:options="question.structure.options"
      />
      <DropdownOptionsEditor
        v-if="question.type === 'dropdown'"
        v-model:options="question.structure.options"
      />

      <RangeEditor
        v-if="question.type === 'range'"
        v-model:structure="question.structure"
      />

      <Input
        v-if="question.type === 'email'"
        disabled placeholder="example@mail.com"
      />
      <Input
        v-if="question.type === 'phone'"
        disabled placeholder="+7 (___) ___ __ __"
      />
    </div>
  </Card>
</template>

<style scoped lang="scss">

.question__header{
  width: 100%; height: 2.75rem;

  display: flex;
  flex-direction: row;
}

@media only screen and (orientation: portrait) and (max-device-width: 414px){
  .question__header{
    height: auto;
    flex-direction: unset;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}
</style>