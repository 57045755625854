<script>
import RadioOption from "@/components/poll/RadioOption.vue";

export default {
  name: "RadioOptions",
  components: {RadioOption},
  props: ['options', 'disabled'],
  methods: {
    selectOption(index) {
      if (this.disabled)
        return

      this.options.forEach(option => option.value = false)
      this.options[index].value = true
    }
  }
}
</script>

<template>
  <div>
    <RadioOption
      v-for="(option, index) in options" :key="index"
      v-model="options[index].value"
      :disabled="disabled"
      @click="() => selectOption(index)"
    >
      {{ option.text }}
    </RadioOption>
  </div>
</template>

<style scoped lang="scss">

</style>