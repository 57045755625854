<script>
import Page from "@/components/summary/Page.vue"
import Question from "@/components/poll/Question.vue"

import request from "@/request"

export default {
  name: "AnswersView",
  components: {Question, Page},
  data() {
    return {
      loading: false,
      responses: [],

      responseID: null,
      responsePages: []
    }
  },
  methods: {
    async getResponses() {
      const response = await request('responses/getResponses', {
        pollID: this.$route.params.id
      })
      if (response) {
        this.responses = response.map((op) => ({text: `@${op.split(':').pop()}`, value: op}))
        if (response.length > 0) {
          this.responseID = response[0]
        }
      }
    },
    async getResponsePages() {
      this.loading = true
      const response = await request('responses/getResponsePages', {
        pollID: this.$route.params.id, responseID: this.responseID
      })
      if (response) {
        this.responsePages = response
        this.loading = false
      }
    }
  },
  watch: {
    responseID() {
      if (this.responseID)
        this.getResponsePages()
    }
  },
  beforeMount() {
    this.getResponses()
  }
}
</script>

<template>
  <div class="column">
    <Card title="Посмотреть ответы" subtitle="Выберите идентификатор ответа">
      <Selector
        :options="responses"
        v-model="responseID"
      />
      <Info
        text='Чтобы посмотреть статистику ответов перейдите в раздел "Сводка"'
        @click="$router.push(`/manage/${$route.params.id}/summary`)"
        style="margin: 1rem 0 0"
      />
    </Card>

    <Loading v-if="loading"/>
    <Page
      v-for="page in this.responsePages"
      :title="page.title"
    >
      <Question
        v-for="question in page.questions"
        :question="question"
        :disabled="true"
      />
    </Page>
  </div>
</template>

<style scoped lang="scss">

</style>