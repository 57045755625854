<script>

export default {
  name: "FinishedView"
}
</script>

<template>
  <div class="inform">
    <svg class="inform__icon" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6374 2.36264L13.3022 0.686818C13.2631 0.491022 13.0215 0.417879 12.8803 0.55907L11.5063 1.93308C11.3422 2.09717 11.25 2.31973 11.25 2.5518V3.68936L8.38852 6.55084C8.26461 6.5177 8.13437 6.50003 8 6.50003C7.17157 6.50003 6.5 7.1716 6.5 8.00003C6.5 8.82846 7.17157 9.50003 8 9.50003C8.82843 9.50003 9.5 8.82846 9.5 8.00003C9.5 7.86566 9.48233 7.73541 9.44919 7.61149L12.3107 4.75002H13.4482C13.6803 4.75002 13.9028 4.65784 14.0669 4.49374L15.441 3.11973C15.5821 2.97854 15.509 2.73697 15.3132 2.69781L13.6374 2.36264Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2 8.00003C2 4.68632 4.68629 2.00003 8 2.00003C8.57535 2.00003 9.13058 2.0808 9.65558 2.23115C10.0538 2.34519 10.469 2.11483 10.5831 1.71663C10.6971 1.31842 10.4668 0.903165 10.0686 0.789124C9.41062 0.600697 8.71643 0.500031 8 0.500031C3.85786 0.500031 0.5 3.85789 0.5 8.00003C0.5 12.1422 3.85786 15.5 8 15.5C12.1421 15.5 15.5 12.1422 15.5 8.00003C15.5 7.2836 15.3993 6.58941 15.2109 5.93147C15.0969 5.53327 14.6816 5.30291 14.2834 5.41695C13.8852 5.53099 13.6548 5.94625 13.7689 6.34445C13.9192 6.86945 14 7.42468 14 8.00003C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8.00003Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5 8.00003C5 6.34318 6.34315 5.00003 8 5.00003C8.11722 5.00003 8.23258 5.00672 8.34577 5.01967C8.7573 5.06677 9.12908 4.77134 9.17618 4.35981C9.22328 3.94828 8.92785 3.57649 8.51632 3.5294C8.34663 3.50998 8.17433 3.50003 8 3.50003C5.51472 3.50003 3.5 5.51475 3.5 8.00003C3.5 10.4853 5.51472 12.5 8 12.5C10.4853 12.5 12.5 10.4853 12.5 8.00003C12.5 7.82327 12.4898 7.6486 12.4698 7.47662C12.4221 7.06517 12.0498 6.77033 11.6384 6.81808C11.2269 6.86582 10.9321 7.23808 10.9798 7.64953C10.9931 7.76424 11 7.88118 11 8.00003C11 9.65689 9.65685 11 8 11C6.34315 11 5 9.65689 5 8.00003Z"/>
    </svg>
    <h1 class="inform__title">Опрос завершён</h1>
    <p class="inform__subtitle">Автор опроса закончил приём ответов</p>

    <router-link to="/home" class="add">
      Проводите свои опросы <br>вместе с
      <img src="@/assets/logo-опросы.svg" alt="logo"/>
    </router-link>
  </div>
</template>

<style scoped lang="scss">

.inform{
  width: 100%; height: calc(100% - 5rem);
  margin-top: 2rem;
  padding: 0 1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  text-align: center;

  &__icon{
    width: 8rem; height: 8rem;
    fill: #4876EAcc;
  }
  &__title{
    font-size: 2rem;
    font-weight: 700;
    color: var(--color-text-default);
  }
  &__subtitle{
    font-size: 1.25rem;
    font-weight: 400;
    color: var(--color-text-mute);
    line-height: 120%;
  }

  .add{
    background-color: var(--color-mute);
    padding: 0.75rem 1.5rem;
    border-radius: 0.75rem;
    margin-top: 2rem;
    text-align: center;

    color: var(--color-text-default);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;

    border: transparent solid 1px;

    img{
      height: 1.25rem;
      margin: 0.125rem;
    }

    cursor: pointer;
    &:hover{
      border-color: var(--color-border-default);
    }
  }
}
</style>