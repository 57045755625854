<script>
export default {
  name: "Answer",
  props: ['text', 'percent']
}
</script>

<template>
  <div class="answer">
    <span class="fill" :style='`width: ${percent}%`'></span>
    <p class="answer__text">{{ text }}</p>
    <p class="answer__percent" v-if="percent">{{ percent }}%</p>
  </div>
</template>

<style scoped lang="scss">

.answer{
  width: 100%; height: 2.75rem;
  padding: 0 1rem;
  border-radius: 0.5rem;
  background-color: var(--color-mute);

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0.25rem 0;

  overflow: hidden;
  position: relative;
  .fill{
    position: absolute;
    top: 0; left: 0;
    height: 2.75rem;
    background-color: #4876EA22;
  }

  &__text{
    font-size: 1rem;
    font-weight: 400;
    color: var(--color-text-default);
  }

  &__percent{
    font-size: 1rem;
    font-weight: 400;
    color: var(--blue);
  }
}
</style>