<script>

export default {
  name: "Toggle",
  props: ['modelValue', 'text'],
  emits: ['update:model-value'],
}
</script>

<template>
  <div class="checkbox">
    {{ text }}
    <label>
      <div></div>
      <input
        type="checkbox"
        :checked="modelValue"
        @click="$emit('update:model-value', $event.target.checked)"
      >
    </label>
  </div>
</template>

<style scoped lang="scss">

.checkbox{
  height: 2.75rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;

  font-size: 1rem;
  font-weight: 400;
  color: var(--color-text-default);
  line-height: 2.75rem;

  input {
    display: none;
  }

  label {
    width: 2.125rem; height: 1.25rem;
    background-color: var(--color-mute);
    border-radius: 1.25rem;
    cursor: pointer;
    transition: background-color .2s;

    div {
      width: 1rem; height: 1rem;
      background-color: var(--white);
      margin: 0.125rem;
      border-radius: 0.5rem;
      transition: margin-left .2s;
    }
  }

  label:has(input:checked) {
    background-color: var(--blue);
    div {
      margin-left: 1rem;
    }
  }
}

</style>