<script>
export default {
  name: "Range",
  props: ['modelValue', 'min', 'max', 'step', 'disabled'],
  emits: ['update:modelValue']
}
</script>

<template>
  <div class="numbers">
    <span v-for="number in (max - min + 1)">
      {{ number * 1 + +min - 1 }}
    </span>
  </div>
  <input
    type="range" :min="min" :max="max" :step="step"
    :value="modelValue"
    @change="$emit('update:model-value', $event.target.value)"
    :disabled="disabled"
  >
</template>

<style scoped lang="scss">

input[type="range"] {
  -webkit-appearance: none;
  width: 100%; height: 2.75rem;
  border-radius: 1rem;
  background-color: var(--color-mute);
  overflow: hidden;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 2.75rem; width: 2.75rem;
  border-radius: 1rem;
  background: #4876EA22;
  box-shadow: calc(-100vw - 22px) 0 0 100vw #4876EA22;
  //backdrop-filter: hue-rotate(180deg) invert(1);
  border: var(--blue) solid 1px;
}

.numbers{
  margin-bottom: -2.75rem;
  width: 100%; height: 2.75rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  span{
    width: 2.75rem;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    color: var(--blue);
  }
}
</style>