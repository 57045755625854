<script>

export default {
  name: "Card",
  props: {
    title: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    }
  }
}
</script>

<template>
  <div class="card">
    <h1 class="card__title" v-if="title">
      {{ title }}
    </h1>
    <p class="card__subtitle" v-if="subtitle">
      {{ subtitle }}
    </p>
    <div class="card__body">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">

.card{
  width: 100%;
  border-radius: 1rem;
  background-color: var(--color-card);
  padding: 2rem;
  box-shadow: 0 0 .5rem #00000008;

  &__title{
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--color-text-default);
    margin-bottom: 1rem;
  }

  &__subtitle{
    font-size: 1rem;
    font-weight: 400;
    color: var(--color-text-mute);
    line-height: 125%;
    margin-bottom: 1.5rem;
  }

  &__body{
    width: 100%;
  }
}

@media only screen and (orientation: portrait) and (max-device-width: 414px){
  .card{
    padding: 1.75rem 1.25rem;
  }
}
</style>