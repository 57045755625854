<script>
import request from "@/request";
import PrimaryButton from "@/components/ui/buttons/PrimaryButton.vue";
import LaconicButton from "@/components/ui/buttons/LaconicButton.vue";

export default {
  name: "ManageView",
  components: {LaconicButton, PrimaryButton},
  data() {
    return {
      pollInfo: {
        status: ''
      }
    }
  },
  methods: {
    async getInfo() {
      const response = await request('polls/getInfo', {id: this.$route.params.id})
      if (response) this.pollInfo = response
    },
  },
  beforeMount() {
    if (!this.$store.getters.getIsAuth) {
      this.$router.push('/')
    }
    this.getInfo()
  },
}
</script>

<template>
  <div class="manage">
    <div class="row">
      <Steps
        :active-index="{'edit': 0, 'settings': 1, 'answers': 2, 'summary': 3}[this.$route.path.split('/').at(-1)]"
        :steps="[
        {text: 'Вопросы', to: `/manage/${$route.params.id}/edit`, locked: this.pollInfo.status !== 'draft'},
        {text: 'Настройка и запуск', to: `/manage/${$route.params.id}/settings`, locked: this.pollInfo.status !== 'draft'},
        {text: 'Ответы', to: `/manage/${$route.params.id}/answers`, locked: this.pollInfo.status === 'draft'},
        {text: 'Сводка', to: `/manage/${$route.params.id}/summary`, locked: this.pollInfo.status === 'draft'}
      ]"
        style="margin-right: auto"
      />

<!--      <PrimaryButton text="Отправить"/>-->
<!--      <PrimaryButton text="Завершить"/>-->
    </div>

    <div class="manage__body">
      <router-view></router-view>
    </div>
  </div>
</template>

<style scoped lang="scss">

.manage {
  max-width: 56rem;
  margin: 0 auto 5rem;
}
</style>