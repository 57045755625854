<script>

export default {
  name: "ThankView"
}
</script>

<template>
  <div class="thank">
    <svg class="thank__icon" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM10.3309 4.5C9.11032 4.5 8.5 5 8.00667 5.63621C7.51335 5 6.90303 4.5 5.68241 4.5C4.65363 4.5 3.51335 5.28406 3.51335 7C3.51335 8.49859 5.00623 10.4334 6.75917 11.5171C7.27864 11.8382 7.64954 11.9959 8.00667 12.0008C8.36381 11.9959 8.73471 11.8382 9.25417 11.5171C11.0071 10.4334 12.5 8.49859 12.5 7C12.5 5.28406 11.3597 4.5 10.3309 4.5Z"/>
    </svg>
    <h1 class="thank__title">Спасибо</h1>
    <p class="thank__subtitle">Ваш ответ отправлен</p>

    <router-link to="/home" class="add">
      Проводите свои опросы <br>вместе с
      <img src="@/assets/logo-опросы.svg" alt="logo"/>
    </router-link>
  </div>
</template>

<style scoped lang="scss">

.thank{
  width: 100%; height: calc(100% - 5rem);
  margin-top: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  &__icon{
    width: 8rem; height: 8rem;
    fill: #7AC70Ccc;
  }
  &__title{
    font-size: 2rem;
    font-weight: 700;
    color: var(--color-text-default);
  }
  &__subtitle{
    font-size: 1.25rem;
    font-weight: 400;
    color: var(--color-text-mute);
  }

  .add{
    background-color: var(--color-mute);
    padding: 0.75rem 1.5rem;
    border-radius: 0.75rem;
    margin-top: 2rem;
    text-align: center;

    color: var(--color-text-default);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;

    border: transparent solid 1px;

    img{
      height: 1.25rem;
      margin: 0.125rem;
    }

    cursor: pointer;
    &:hover{
      border-color: var(--color-border-default);
    }
  }
}
</style>