<script>
import request from "@/request";
import {notify} from "@kyvg/vue3-notification";

export default {
  name: "SettingsView",
  data() {
    return {
      respondents: 50,
      allowRepeat: false,
      link: `${this.$store.getters.getURL}/poll/${this.$route.params.id}/0`
    }
  },
  methods: {
    copyToClipboard(text) {
      const textarea = document.createElement("textarea");
      textarea.value = text
      document.body.appendChild(textarea)
      textarea.select()
      textarea.setSelectionRange(0, 99999)
      document.execCommand("copy")
      document.body.removeChild(textarea)
      notify('Ссылка скопирована')
    },
    async start() {
      const response = await request('polls/start', { pollID: this.$route.params.id })
      if (response) notify({text: 'Опрос успешно запущен', type: 'success'})
      this.$router.push('/home')
    }
  },
}
</script>

<template>
  <div class="column">
<!--    <Card-->
<!--      title="Респонденты"-->
<!--      subtitle="Вы можете опросить до 100 людей"-->
<!--    >-->
<!--      <NumberInput-->
<!--        placeholder="Количество респондентов"-->
<!--        v-model="respondents"-->
<!--        min="10" max="100" step="10"-->
<!--      />-->
<!--      <Toggle-->
<!--        text="Разрешить повторное прохождение опроса"-->
<!--        v-model="allowRepeat"-->
<!--        style="flex-direction: row-reverse; justify-content: start; margin-top: 0.5rem"-->
<!--      />-->
<!--    </Card>-->
    <Card
      title="Ссылка"
      subtitle="Скопируйте ссылку и отправьте своим респондентам"
    >
      <div class="copyLink">
        <Input disabled v-model="link"/>
        <LaconicButton
          @click="() => copyToClipboard(link)"
          style="height: 2.75rem"
        >
          <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 0C5.7835 0 5 0.783502 5 1.75V9.25C5 10.2165 5.7835 11 6.75 11H14.25C15.2165 11 16 10.2165 16 9.25V1.75C16 0.783502 15.2165 0 14.25 0H6.75ZM6.5 1.75C6.5 1.61193 6.61193 1.5 6.75 1.5H14.25C14.3881 1.5 14.5 1.61193 14.5 1.75V9.25C14.5 9.38807 14.3881 9.5 14.25 9.5H6.75C6.61193 9.5 6.5 9.38807 6.5 9.25V1.75Z"/>
            <path d="M1.75 5C0.783502 5 0 5.7835 0 6.75V14.25C0 15.2165 0.783502 16 1.75 16H9.25C10.2165 16 11 15.2165 11 14.25V12.75C11 12.3358 10.6642 12 10.25 12C9.83579 12 9.5 12.3358 9.5 12.75V14.25C9.5 14.3881 9.38807 14.5 9.25 14.5H1.75C1.61193 14.5 1.5 14.3881 1.5 14.25V6.75C1.5 6.61193 1.61193 6.5 1.75 6.5H3.25C3.66421 6.5 4 6.16421 4 5.75C4 5.33579 3.66421 5 3.25 5H1.75Z"/>
          </svg>
        </LaconicButton>
      </div>
    </Card>
    <Warning text="Редактирование вопросов будет недоступно"/>
    <BigBlueButton
      text="Запустить"
      @click="start"
    />
  </div>
</template>

<style scoped lang="scss">

.copyLink{
  width: 100%; height: 2.75rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}
</style>