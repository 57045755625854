<script>

export default {
  name: "Info",
  props: ['text']
}
</script>

<template>
  <div class="info">
    <svg class='info__icon' viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM6.5 7.75C6.5 7.33579 6.83579 7 7.25 7H8.25C8.66421 7 9 7.33579 9 7.75V10.5H9.25C9.66421 10.5 10 10.8358 10 11.25C10 11.6642 9.66421 12 9.25 12H7.25C6.83579 12 6.5 11.6642 6.5 11.25C6.5 10.8358 6.83579 10.5 7.25 10.5H7.5V8.5H7.25C6.83579 8.5 6.5 8.16421 6.5 7.75ZM8 6C8.55229 6 9 5.55228 9 5C9 4.44772 8.55229 4 8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6Z"/>
    </svg>
    <p class="info__text">{{ text }}</p>
  </div>
</template>

<style scoped lang="scss">

.info{
  width: 100%; min-height: 2rem;
  padding: 0.25rem 0;

  display: flex;
  flex-direction: row;

  &__icon{
    min-width: 1rem; min-height: 1rem;
    max-width: 1rem; max-height: 1rem;
    margin: 0.25rem 0.5rem;
    fill: var(--blue);
  }

  &__text{
    font-size: 1rem;
    font-weight: 400;
    color: var(--blue);
    line-height: 1.5rem;
  }
}
</style>