<script>
import Answer from "@/components/summary/Answer.vue"

export default {
  name: "QuestionResult",
  components: {Answer},
  props: ['question'],
}
</script>

<template>
  <Card :title="question.text">

    <div v-if="['shortText', 'longText', 'number', 'email'].includes(question.type)">
      <Answer
        v-for="response in question.responses"
        :text="response"
      />
      <p v-if="question.responses.length === 0">
        Нет ответов
      </p>
    </div>

    <Answer
      v-if="['radio', 'checkbox', 'dropdown'].includes(question.type)"
      v-for="option in question.options"
      :text="option.text"
      :percent="
        (option.value /
        Math.max(1, question.options.reduce((a, b) => a += b.value, 0)) * 100)
        .toFixed(1)
      "
    />

    <Answer
      v-if="question.type === 'range'"
      v-for="value in Object.keys(question.values)"
      :text="value"
      :percent="
        (question.values[value] /
        Math.max(1, Object.values(question.values).reduce((a, b) => a += b, 0)) * 100)
        .toFixed(1)
      "
    />
  </Card>
</template>

<style scoped lang="scss">

</style>