<script>

export default {
  name: "Warning",
  props: ['text']
}
</script>

<template>
  <div class="warning">
    <svg class="warning__icon" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.22048 1.75361C8.1263 1.57741 7.87369 1.57741 7.77951 1.75362L1.69787 13.1321C1.60886 13.2987 1.72953 13.5 1.91835 13.5H14.0816C14.2705 13.5 14.3911 13.2987 14.3021 13.1321L8.22048 1.75361ZM6.45662 1.04655C7.11588 -0.186903 8.88412 -0.186904 9.54338 1.04655L15.625 12.4251C16.2481 13.5908 15.4034 15 14.0816 15H1.91835C0.596554 15 -0.248093 13.5908 0.374974 12.4251L6.45662 1.04655ZM9 11C9 11.5523 8.55229 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55229 10 9 10.4477 9 11ZM8.75 5.75003C8.75 5.33582 8.41421 5.00003 8 5.00003C7.58579 5.00003 7.25 5.33582 7.25 5.75003V8.25003C7.25 8.66424 7.58579 9.00003 8 9.00003C8.41421 9.00003 8.75 8.66424 8.75 8.25003V5.75003Z"/>
    </svg>
    <p class="warning__text">{{ text }}</p>
  </div>
</template>

<style scoped lang="scss">

.warning{
  width: 100%; min-height: 2rem;
  padding: 0.25rem 0;

  display: flex;
  flex-direction: row;

  .warning__icon{
    min-width: 1rem; min-height: 1rem;
    max-width: 1rem; max-height: 1rem;
    margin: 0.25rem 0.5rem;
    fill: var(--red);
  }

  .warning__text{
    font-size: 1rem;
    font-weight: 400;
    color: var(--red);
    line-height: 1.5rem;
  }
}
</style>