<script>

export default {
  name: "Selector",
  props: ['modelValue', 'options', 'disabled'],
  emits: ['update:model-value'],
  data() {
    return {
      showOptions: false,
    }
  },
  methods: {
    showHideOptions() {
      if (!this.disabled) {
        this.showOptions = !this.showOptions
      }
    }
  },
  computed: {
    selectedOption() {
      return this.options.find((op) => op.value === this.modelValue) || this.options[0]
    }
  }
}
</script>

<template>
  <div class="select" @click="showHideOptions" v-if="selectedOption">
    <img v-if='selectedOption.icon' :src="selectedOption.icon" alt="">
    {{ selectedOption.text }}

    <svg class='select__arrow' :class='{rotated: showOptions}' viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7803 5.21967C13.0732 5.51256 13.0732 5.98744 12.7803 6.28033L8.53033 10.5303C8.23744 10.8232 7.76256 10.8232 7.46967 10.5303L3.21967 6.28033C2.92678 5.98744 2.92678 5.51256 3.21967 5.21967C3.51256 4.92678 3.98744 4.92678 4.28033 5.21967L8 8.93934L11.7197 5.21967C12.0126 4.92678 12.4874 4.92678 12.7803 5.21967Z" fill="#1F2328"/>
    </svg>

    <div class="select__options" v-show="showOptions">
      <div v-for="option in options">
        <div class="divider" v-if="option.divider"></div>
        <div
          v-else
          class="option"
          @click="$emit('update:model-value', option.value)"
        >
          <img v-if='option.icon' :src="option.icon" alt="">
          {{ option.text }}
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">

.select{
  width: max-content; height: 2.75rem;
  background-color: var(--white);
  padding: 0 0.75rem;
  border: var(--color-border-default) solid 1px;
  border-radius: 0.75rem;

  font-size: 1rem;
  font-weight: 400;
  line-height: 2.75rem;
  color: var(--color-text-default);

  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
  &:hover{
    border-color: #ccc;
  }

  &__arrow {
    width: 1rem; height: 1rem;
    margin-left: 1.5rem;

    &.rotated{
      transform: rotate(180deg);
    }
  }
  img{
    width: 1rem; height: 1rem;
    margin-right: 0.5rem;
  }

  position: relative;
  &__options{
    position: absolute;
    top: 3rem; left: 0;
    width: 16rem;
    background-color: var(--white);
    border-radius: 0.75rem;
    border: var(--color-border-default) solid 1px;

    max-height: 21.5rem;
    overflow: auto;
    .divider{
      width: 100%; height: 0.25rem;
      background-color: var(--color-mute);
    }

    z-index: 1;
  }
}
.option{
  height: 2.5rem; width: 100%;
  padding: 0 1rem;
  background-color: var(--white);

  font-size: 1rem;
  font-weight: 400;
  color: var(--color-text-default);

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  img, svg{
    width: 1rem; height: 1rem;
    fill: var(--blue);
  }

  cursor: pointer;
  &:hover{
    background-color: var(--color-mute);
  }
}
</style>