<script>
import RadioOption from "@/components/poll/RadioOption.vue";

export default {
  name: "RadioOptionsEditor",
  components: {RadioOption},
  props: ['options'],
  emits: ['update:options'],
  data() {
    return {
      limits: {
        MIN_OPTIONS: 2, MAX_OPTIONS: 10
      }
    }
  },
  methods: {
    addOption() {
      if (this.options.length >= this.limits.MAX_OPTIONS)
        return

      this.options.push({text: '', value: false})
      this.$emit('update:options', this.options)
    },
    removeOption(option) {
      if (this.options.length <= this.limits.MIN_OPTIONS)
        return

      let index = this.options.indexOf(option)
      this.options.splice(index, 1)
      this.$emit('update:options', this.options)
    }
  }
}
</script>

<template>
  <div>
    <RadioOption
      v-for="(option, index) in options"
      :key="index"
      :name="options"
      :disabled="true"
    >
      <TransparentInput
        :placeholder="`Ответ ${index + 1}`"
        v-model="options[index].text"
        maxlength="64"
      />
      <TransparentButton
        title="Удалить"
        v-if="options.length > limits.MIN_OPTIONS"
        @click="() => removeOption(option)"
      >
        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.5 1.75C6.5 1.61193 6.61193 1.5 6.75 1.5H9.25C9.38807 1.5 9.5 1.61193 9.5 1.75V3H6.5V1.75ZM11 1.75V3H13.25C13.6642 3 14 3.33579 14 3.75C14 4.16421 13.6642 4.5 13.25 4.5H2.75C2.33579 4.5 2 4.16421 2 3.75C2 3.33579 2.33579 3 2.75 3H5V1.75C5 0.783502 5.7835 0 6.75 0H9.25C10.2165 0 11 0.783502 11 1.75ZM4.49627 6.67537C4.45506 6.26321 4.08753 5.9625 3.67537 6.00372C3.26321 6.04493 2.9625 6.41247 3.00372 6.82462L3.66367 13.4241C3.75313 14.3187 4.50592 15 5.40498 15H10.595C11.4941 15 12.2469 14.3187 12.3363 13.4241L12.9963 6.82462C13.0375 6.41247 12.7368 6.04493 12.3246 6.00372C11.9125 5.9625 11.5449 6.26321 11.5037 6.67537L10.8438 13.2749C10.831 13.4027 10.7234 13.5 10.595 13.5H5.40498C5.27655 13.5 5.169 13.4027 5.15622 13.2749L4.49627 6.67537Z"/>
        </svg>
      </TransparentButton>
    </RadioOption>

    <div class="buttons">
      <LaconicButton text="Добавить ответ" @click="addOption">
        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.75 2C7.94891 2 8.13968 2.07902 8.28033 2.21967C8.42098 2.36032 8.5 2.55109 8.5 2.75V7H12.75C12.9489 7 13.1397 7.07902 13.2803 7.21967C13.421 7.36032 13.5 7.55109 13.5 7.75C13.5 7.94891 13.421 8.13968 13.2803 8.28033C13.1397 8.42098 12.9489 8.5 12.75 8.5H8.5V12.75C8.5 12.9489 8.42098 13.1397 8.28033 13.2803C8.13968 13.421 7.94891 13.5 7.75 13.5C7.55109 13.5 7.36032 13.421 7.21967 13.2803C7.07902 13.1397 7 12.9489 7 12.75V8.5H2.75C2.55109 8.5 2.36032 8.42098 2.21967 8.28033C2.07902 8.13968 2 7.94891 2 7.75C2 7.55109 2.07902 7.36032 2.21967 7.21967C2.36032 7.07902 2.55109 7 2.75 7H7V2.75C7 2.55109 7.07902 2.36032 7.21967 2.21967C7.36032 2.07902 7.55109 2 7.75 2Z"/>
        </svg>
      </LaconicButton>
    </div>
  </div>
</template>

<style scoped lang="scss">

.buttons{
  width: 100%; height: 2.75rem;

  display: flex;
  flex-direction: row;
  gap: 1rem;

  margin-top: 1rem;
}
</style>