<script>

export default {
  name: "InputArea",
  props: ['modelValue'],
  emits: ['update:modelValue']
}
</script>

<template>
  <textarea
    :value="modelValue"
    @change="$emit('update:model-value', $event.target.value)"
  />
</template>

<style scoped lang="scss">

textarea{
  width: 100%; min-height: 8.25rem;
  resize: none;
  padding: 0.75rem 1rem;
  border: var(--color-border-default) solid 1px;
  border-radius: 0.75rem;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: var(--color-text-default);

  &::placeholder {
    color: var(--color-text-mute);
  }
  &:disabled{
    background-color: var(--color-mute);
    color: var(--color-text-mute);
  }
}
</style>