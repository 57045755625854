<script>

export default {
  name: "TransparentInput",
  props: ['modelValue'],
  emits: ['update:modelValue']
}
</script>

<template>
  <input
    type="text"
    maxlength="100"
    :value="modelValue"
    @change="$emit('update:model-value', $event.target.value)"
  >
</template>

<style scoped lang="scss">

input{
  width: 100%; height: 2.75rem;

  background-color: transparent;

  font-size: 1rem;
  font-weight: 400;
  color: var(--color-text-default);

  &::placeholder {
    color: var(--color-text-mute);
  }
}
</style>