<script>
import RadioOptions from "@/components/poll/RadioOptions.vue"
import CheckboxOptions from "@/components/poll/CheckboxOptions.vue"

export default {
  name: "Question",
  components: {CheckboxOptions, RadioOptions},
  props: ['question', 'disabled'],
}
</script>

<template>
  <Card class="question" style="padding-top: 1.25rem">
    <div class="question__header" style="margin-bottom: 1rem">
      <p class="question__text">{{ question.text }}</p>
      <div class="required" v-if="question.required">＊</div>
    </div>

    <Input
      v-if="question.type === 'shortText'"
      placeholder="Введите ответ"
      v-model="question.structure.text"
      :disabled="disabled"
      maxlength="100"
      style="margin-bottom: 1rem"
    />
    <InputArea
      v-if="question.type === 'longText'"
      maxlength="400"
      placeholder="Введите ответ"
      v-model="question.structure.text"
      :disabled="disabled"
    />
    <InputNumber
      v-if="question.type === 'number'"
      v-model="question.structure.number"
      :min="question.structure.min" :max="question.structure.max"
      :placeholder="
        'Введите число'
         + (question.structure.min !== -10000000 ? ` от ${question.structure.min} ` : '')
         + (question.structure.max !== 10000000 ? ` до ${question.structure.max} ` : '')
        "
      :disabled="disabled"
      style="margin-bottom: 1rem"
    />
    <RadioOptions
      v-if="question.type === 'radio'"
      v-model:options="question.structure.options"
      :disabled="disabled"
    />
    <CheckboxOptions
      v-if="question.type === 'checkbox'"
      v-model:options="question.structure.options"
      :disabled="disabled"
    />
    <Selector
      v-if="question.type === 'dropdown'"
      :options="question.structure.options"
      v-model="question.structure.value"
      :disabled="disabled"
    />
    <Range
      v-if="question.type === 'range'"
      v-model="question.structure.value"
      :min="question.structure.min"
      :max="question.structure.max"
      :step="1"
      :disabled="disabled"
    />
    <Input
      v-if="question.type === 'email'"
      type="email"
      placeholder="example@mail.com"
      v-model="question.structure.email"
      :disabled="disabled"
      maxlength="100"
      style="margin-bottom: 1rem"
    />
  </Card>
</template>

<style scoped lang="scss">

.question{
  border: transparent solid 1px;

  &__header{
    width: 100%; min-height: 2.75rem;

    display: flex;
    flex-direction: row;
    align-items: center;

    .question__text{
      flex: 1 0;

      font-size: 1.25rem;
      line-height: 120%;
      font-weight: 600;
      color: var(--color-text-default);
    }

    .required{
      width: 2.75rem; height: 2.75rem;
      font-size: 2rem;
      line-height: 2.75rem;
      text-align: center;
      color: #D3313177;
    }
  }
}
</style>