<script>

export default {
  name: "Loading"
}
</script>

<template>
  <div class="loading">
    <svg class="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle class="spinner__circle" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
  </div>
</template>

<style scoped lang="scss">

.loading {
  width: 100%;
  padding: 4rem 0;
  display: flex; justify-content: center;
}

$offset: 187;
$duration: 1.4s;

.spinner {
  width: 2rem; height: 2rem;
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

.spinner__circle {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  stroke: var(--blue);
  transform-origin: center;
  animation: dash $duration ease-in-out infinite;
  //animation:
  //    dash $duration ease-in-out infinite,
  //    colors ($duration*4) ease-in-out infinite;
}

//@keyframes colors {
//  0% { stroke: var(--blue) }
//  25% { stroke: var(--red) }
//  50% { stroke: var(--blue)}
//  75% { stroke: var(--green) }
//  100% { stroke: var(--blue) }
//}

@keyframes dash {
  0% { stroke-dashoffset: $offset; }
  50% {
    stroke-dashoffset: $offset/4;
    transform:rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform:rotate(450deg);
  }
}
</style>