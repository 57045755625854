<script>
export default {
  name: "Status",
  props: {
    status: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      statuses: {
        draft: "Черновик",
        active: "Сбор ответов",
        finished: "Завершен"
      }
    }
  }
}
</script>

<template>
  <div class="status" :class="`${status}`">
    <div class="status__indicator"></div>
    <div class="status__text">
      {{ statuses[status] }}
    </div>
  </div>
</template>

<style scoped lang="scss">

.status{
  height: 1.5rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  &__indicator{
    min-width: 0.375rem; height: 0.375rem;
    margin: 0.375rem;
    border-radius: 3px;
    background-color: var(--grey);
  }

  &__text{
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: var(--grey);
    text-transform: uppercase;
  }

  &.active{
    .status__text{
      color: var(--color-text-default);
    }
    .status__indicator{
      background-color: var(--blue);
    }
  }
  &.finished{
    .status__text{
      color: var(--color-text-default);
    }
    .status__indicator{
      background-color: var(--green-mute);
    }
  }
}
</style>