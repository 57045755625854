<script>
import Header from "@/components/header/Header.vue"
import {Notifications} from "@kyvg/vue3-notification"

export default {
  components: {
    Notifications, Header
  },
  beforeMount() {
    this.$store.dispatch('updateIsAuth')
  }
}
</script>

<template>
  <div class="app" mode="light">
    <main class="container">
      <router-view></router-view>
    </main>
    <Header/>
    <notifications
      style="padding: 0.75rem 0.5rem; width: 24rem"
      position="bottom left"
    />
  </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

#app {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 16px;
}

:root {
  --white: #fefefe;
  --white-mute: #f7f8fb;

  --grey: #1A2B4D99;
  --grey-mute: #2c383f;

  --black: #0d1117;
  --black-mute: #23272d;

  --blue: #4876ea;
  --blue-mute: #1cb0ff;

  --green: #8ee000;
  --green-mute: #7ac70c;

  --red: #ca6a36;
  --red-mute: #d33131;
}

.app[mode=light] {
  --color-text-default: var(--black);
  --color-text-mute: var(--grey);

  --color-background: var(--white-mute);
  --color-card: var(--white);
  --color-page: #eff2f7;

  --color-mute: #b0bdd633;
  --color-border-default: #3c3c3c22;
}

//.app[mode=dark] {}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background: #0000;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 0.25rem;
  border: 1px solid var(--color-background);
}

.app {
  width: 100%; height: 100vh;
  padding-top: 5rem;
  background-color: var(--color-background);

  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.container{
  max-width: 100%; height: calc(100vh - 5rem);
  overflow: auto;
  scroll-behavior: smooth;

  $width: 72rem;
  padding-right: max((100% - $width) / 2, 2rem);
  padding-left: max((100% - $width) / 2, 2rem);
}

@media only screen and (orientation: portrait) and (max-device-width: 414px){
  .container{
    padding: 0 0.25rem;
  }
}

.vue-notification {
  width: max-content; max-width: calc(100% - 4.75rem);
  margin-bottom: 0.25rem;
  padding: 0.5rem 4rem 0.5rem 0.75rem;
  font-size: 0.875rem;
  color: #fff;
  line-height: 1.25rem;
  border-radius: 0.5rem;

  background: var(--black-mute) !important;
  background-color: var(--black-mute) !important;
  border-left: 0.375rem solid var(--blue);

  //position: relative;
  //&::before{
  //  content: "✔";
  //  position: absolute;
  //  top: 0.5rem; left: 0.5rem;
  //  line-height: 1.5rem;
  //  color: var(--green-mute);
  //}

  &.success {
    border-left-color: #7AC70C;
  }
  &.warn {
    border-left-color: #f48a06;
  }
  &.error {
    border-left-color: #f5584e;
  }
}

.row{
  width: 100%;

  display: flex;
  flex-direction: row;
  gap: 1.25rem;

  align-items: center;
}
.column{
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

</style>
