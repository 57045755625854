import { createRouter, createWebHistory } from 'vue-router'

import LandingView from "@/views/LandingView.vue"
import HomeView from '@/views/HomeView.vue'

import ManageView from "@/views/manage/ManageView.vue"
import EditView from "@/views/manage/EditView.vue"
import SettingsView from "@/views/manage/SettingsView.vue"
import AnswersView from "@/views/manage/AnswersView.vue"
import SummaryView from "@/views/manage/SummaryView.vue"

import DemoView from "@/views/DemoView.vue"
import PollView from "@/views/PollView.vue"

import ThankView from "@/views/ThankView.vue"
import FinishedView from "@/views/FinishedView.vue"


const routes = [
  {
    path: '/',
    name: 'landing',
    component: LandingView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/manage/:id',
    name: 'manage',
    component: ManageView,
    children: [
      {
        path: 'edit',
        component: EditView,
      },
      {
        path: 'settings',
        component: SettingsView,
      },
      {
        path: 'answers',
        component: AnswersView,
      },
      {
        path: 'summary',
        component: SummaryView,
      },
    ]
  },
  {
    path: '/demo/:id/:page',
    name: 'demo',
    component: DemoView,
  },
  {
    path: '/poll/:id/:page',
    name: 'poll',
    component: PollView,
  },
  {
    path: '/thank',
    name: 'thank',
    component: ThankView
  },
  {
    path: '/finished',
    name: 'finished',
    component: FinishedView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

export default router
