import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import { VueTelegramPlugin } from 'vue-tg'

import components from './components/ui'

const app = createApp(App)

components.forEach(component => {
    app.component(component.name, component)
})

app.use(store).use(router).use(VueTelegramPlugin).mount('#app')
