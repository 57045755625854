<script>

export default {
  name: "InputNumber",
  props: ['modelValue', 'min', 'max'],
  emits: ['update:model-value'],
  methods: {
    validate() {
      if (this.modelValue === '')
        return
      if (+this.modelValue < +this.min)
        this.$emit('update:model-value', this.min)
      if (+this.modelValue > +this.max)
        this.$emit('update:model-value', this.max)
    }
  }
}
</script>

<template>
  <input
    type="number"
    :value="modelValue"
    :min="min" :max="max"
    @change="$emit('update:model-value', $event.target.value)"
    @blur="validate"
  >
</template>

<style scoped lang="scss">

input{
  width: 100%; height: 2.75rem;
  padding: 0.75rem 1rem;
  border: var(--color-border-default) solid 1px;
  border-radius: 0.75rem;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: var(--color-text-default);

  &::placeholder {
    color: var(--color-text-mute);
  }
  &:disabled{
    background-color: var(--color-mute);
  }
}
input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>