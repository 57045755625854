<script>
import Question from "@/components/poll/Question.vue"

import {notify} from "@kyvg/vue3-notification"
import request from "@/request"

export default {
  name: "PollView",
  components: {Question},
  data() {
    return {
      loading: true,
      poll: {
        info: {title: ''},
        pages: []
      },
      highlightRequiredQuestions: false
    }
  },
  methods: {
    async getInfo() {
      const pollInfo = await request('polls/getInfo', {id: this.$route.params.id})
      if (pollInfo) {
        if (pollInfo.status === 'draft') {
          this.$router.push('/home')
        }
        if (pollInfo.status === 'active' && this.$store.getters.getIsAuth) {
          const response = await request('responses/canIRespond', { pollID: this.$route.params.id })
          if (response.bool === false) {
            this.$router.push('/thank')
          }
        }
        if (pollInfo.status === 'finished') {
          this.$router.push('/finished')
        }
        this.poll.info = pollInfo
        await this.getPages()
      }
    },
    async getPages() {
      const response = await request('polls/getPages', {id: this.$route.params.id})
      if (response) {
        this.poll.pages = response
        this.loading = false
      }
    },

    checkPage(pageIndex) {
      for (const question of this.poll.pages[pageIndex].questions) {
        if (question.required) {
          switch (question.type) {
            case "shortText":
            case "longText":
              if (question.structure.text === '') {
                return false
              }
              break
            case "radio":
            case "checkbox":
              if (question.structure.options.every((op) => op.value === false)) {
                return false
              }
              break
            case 'dropdown':
              if (question.structure.value === null) {
                return false
              }
              break
            case 'number':
              break // todo
            case 'email':
              break // todo
          }
        }
      }
      return true
    },
    check() {
      for (const [pageIndex, ] of this.poll.pages.entries()) {
        if (!this.checkPage(pageIndex)) {
          return false
        }
      }
      return true
    },
    nextPage() {
      if (!this.checkPage(this.$route.params.page)) {
        notify('Ответьте на обязательные вопросы')
        this.highlightRequiredQuestions = true
      }
      else {
        this.$router.push(`./${+this.$route.params.page + 1}`)
        this.highlightRequiredQuestions = false
      }
    },
    async respond() {
      if (!this.check()) {
        notify('Ответьте на обязательные вопросы')
        this.highlightRequiredQuestions = true
        return
      }

      let response
      if (this.$store.getters.getIsAuth) {
        response = request('responses/addSignedResponse',
          {
            pollID: this.$route.params.id,
            response: this.poll.pages
          })
      }
      else {
        response = request('responses/addResponse',
          {
            pollID: this.$route.params.id,
            response: this.poll.pages
          })
      }

      if (response) {
        notify({title: 'Ответ отправлен', type: 'success'})
        this.$router.push('/thank')
      }
    },
  },
  beforeMount() {
    this.getInfo()
  }
}
</script>

<template>
  <div class="poll">
    <h1 class="poll__title">{{ poll.info.title }}</h1>
    <div class="poll__header" @click.stop>
      <Steps
        :steps="poll.pages.map((page, index) => ({text: page.title, to: `./${index}`, locked: false}))"
        :activeIndex="+$route.params.page"
      />
    </div>

    <Loading v-if="loading"/>
    <div class="poll__body" v-if="poll.pages.length > 0">
      <Question
        v-for="(question, index) in poll.pages[$route.params.page].questions"
        v-model:question="poll.pages[$route.params.page].questions[index]"
        :class="{highlight: question.required && this.highlightRequiredQuestions && ((question.type === 'text' && question.answer === '') || (question.type !== 'text' && question.options.every((op) => op.value === false)))}"
      />

      <BigBlueButton
        v-if="+$route.params.page === poll.pages.length - 1"
        text="Завершить"
        @click="respond"
      />
      <BigBlueButton
        v-else
        text="Далее"
        @click="nextPage"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">

.poll {
  max-width: 56rem;
  margin: 0 auto 5rem;

  &__title {
    font-size: 2rem;
    font-weight: 600;

    margin: 1rem 0;
  }

  &__header {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }

  &__body {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .highlight{
    border: var(--red) solid 1px;
  }
}
</style>