<script>

export default {
  name: "Input",
  props: ['modelValue'],
  emits: ['update:modelValue']
}
</script>

<template>
  <input
    type="text" maxlength="100"
    :value="modelValue"
    @change="$emit('update:model-value', $event.target.value)"
  >
</template>

<style scoped lang="scss">

input{
  width: 100%; height: 2.75rem;
  padding: 0.75rem 1rem;
  border: var(--color-border-default) solid 1px;
  border-radius: 0.75rem;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: var(--color-text-default);

  &::placeholder {
    color: var(--color-text-mute);
  }
  &:disabled{
    background-color: var(--color-mute);
    color: var(--color-text-mute);
  }
}
</style>