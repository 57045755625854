<script>
export default {
  name: "PrimaryButton",
  props: {
    text: {
      type: String,
      required: false
    }
  }
}
</script>

<template>
  <button class="button">
    <slot></slot>
    <span class="button__text" v-if="text">
      {{ text }}
    </span>
  </button>
</template>

<style scoped lang="scss">
.button{
  &:deep(svg){
    width: 1rem; height: 1rem;
    margin: 0.75rem 0 0.75rem 0;
    fill: var(--color-text-mute);
    transition: all .1s;
  }

  &:hover:deep(svg){
    fill: var(--white);
  }
}

.button{
  height: 2.5rem;
  padding: 0 0.75rem;
  background-color: var(--color-mute);
  border-radius: 0.5rem;
  transition: all .1s;

  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  &__text{
    line-height: 2.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--color-text-mute);
    transition: all .1s;
  }

  &:hover{
    background-color: var(--blue);
    .button__text{
      color: var(--white);
    }
  }
}
</style>