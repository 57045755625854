<script>

export default {
  name: "RangeEditor",
  props: ['structure']
}
</script>

<template>
  <div>
    <Range
      v-model="structure.value"
      :min="structure.min"
      :max="structure.max"
      :step="1"
    />
    <div class="buttons">
      Шкала от
      <Selector
        v-model="structure.min"
        :options="[
          {text: '0', value: 0},
          {text: '1', value: 1},
        ]"
      />
      до
      <Selector
        v-model="structure.max"
        :options="[
          {text: '1', value: 1},
          {text: '2', value: 2},
          {text: '3', value: 3},
          {text: '4', value: 4},
          {text: '5', value: 5},
          {text: '6', value: 6},
          {text: '7', value: 7},
          {text: '8', value: 8},
          {text: '9', value: 9},
          {text: '10', value: 10}
        ]"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">

.buttons{
  width: 100%; height: 2.75rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  margin-top: 1rem;
}
</style>