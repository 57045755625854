import { createStore } from 'vuex'
import request from '@/request'

export default createStore({
  state: {
    URL: 'https://tg-forms.ru',

    isAuth: false,
    user: {
      id: 0,
      name: '',
      pic: '',
      plan: ''
    },
  },
  getters: {
    getURL(state) {
      return state.URL
    },
    getIsAuth(state) {
      return state.isAuth
    },

    getUsername(state){
      return state.user.name
    },
    getUserpic(state) {
      return state.user.pic
    },
    getPlan(state) {
      return state.user.plan
    },
  },
  mutations: {
    async updateIsAuth(state){
      let userData = await request('auth/isAuth')
      if (userData) {
        state.isAuth = true

        state.user.id = userData.id
        state.user.name = userData.username
        state.user.pic = userData.userpic
        state.user.plan = userData.subscription
      }
    }
  },
  actions: {
    updateIsAuth(ctx){
      ctx.commit('updateIsAuth')
    }
  },
  modules: {
  }
})
