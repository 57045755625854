<script>
import PageEditor from "@/components/edit/PageEditor.vue"
import QuestionEditor from "@/components/edit/QuestionEditor.vue"

import request from "@/request"
import {notify} from "@kyvg/vue3-notification"

export default {
  name: "EditView",
  components: {PageEditor, QuestionEditor},
  data() {
    return {
      loading: true,
      poll: {
        info: {
          title: ''
        },
        pages: []
      },
      limits: {
        'free': { MAX_PAGES: 1, MAX_QUESTIONS: 5 },
        'standard': { MAX_PAGES: 3, MAX_QUESTIONS: 10 },
        'premium': { MAX_PAGES: 5, MAX_QUESTIONS: 100 },
      }[this.$store.getters.getPlan || 'free']
    }
  },
  methods: {
    async getInfo() {
      const response = await request('polls/getInfo', {id: this.$route.params.id})
      if (response) {
        if (response.status !== 'draft') {
          this.$router.push('/home')
        }
        else {
          this.poll.info = response
          await this.getPages()
        }
      }
    },
    async getPages() {
      const response = await request('polls/getPages', {id: this.$route.params.id})
      if (response) {
        this.poll.pages = response
        this.loading = false
      }
    },

    async save() {
      this.setDefaultInputs()
      await request('polls/save', { pollID: this.poll.info.id, poll: this.poll })
    },
    setDefaultInputs() {
      if (this.poll.info.title === '') {
        this.poll.info.title = "Нет заголовка"
      }
      for (let page of this.poll.pages) {
        for (let question of page.questions) {
          if (question.text === '') {
            question.text = 'Нет вопроса'
          }
          switch (question.type) {
            case 'shortText':
            case 'longText':
              break
            case 'radio':
            case 'checkbox':
              for (let [index, option] of question.structure.options.entries()) {
                if (option.text === '') {
                  option.text = `Ответ ${index + 1}`
                }
              }
              break
            case 'number':
              if (question.structure.min === '')
                question.structure.min = -10000000
              if (question.structure.max === '')
                question.structure.max = 10000000
          }
        }
      }
    },

    addPage(pageIndex) {
      if (this.poll.pages.length >= this.limits.MAX_PAGES)
        return

      this.poll.pages.splice(pageIndex + 1, 0, {title: `Страница ${pageIndex + 2}`, questions: []})
      this.addQuestion(pageIndex + 1)
    },
    removePage(pageIndex) {
      if (this.poll.pages.length === 1)
        return

      this.poll.pages.splice(pageIndex, 1)
    },

    addQuestion(pageIndex) {
      if (this.poll.pages[pageIndex].questions.length >= this.limits.MAX_QUESTIONS)
        return

      this.poll.pages[pageIndex].questions.push({
        type: 'shortText',
        text: '',
        required: true,
        structure: {text: ''}
      })
    },
    removeQuestion(pageIndex, questionIndex) {
      if (this.poll.pages[pageIndex].questions.length === 1)
        return

      this.poll.pages[pageIndex].questions.splice(questionIndex, 1)
    },
    copyQuestion(pageIndex, questionIndex) {
      if (this.poll.pages[pageIndex].questions.length >= this.limits.MAX_QUESTIONS)
        return

      let question = this.poll.pages[pageIndex].questions[questionIndex]
      let copy = JSON.parse(JSON.stringify(question))
      this.poll.pages[pageIndex].questions.splice(questionIndex, 0, copy)
    },
  },

  beforeMount() {
    this.getInfo()
  },
  beforeUnmount() {
    this.save()
    notify({title: 'Автосохранение'})
  }
}
</script>

<template>
  <div class="column">
    <Card
      title="Стандартный опрос"
      :subtitle="
        `В этом опросе можно создать до ${limits.MAX_PAGES} страниц.
         Одна страница может содержать до ${limits.MAX_QUESTIONS} вопросов`
      "
    >
      <Input
        v-model="poll.info.title"
        placeholder="Заголовок опроса"
        style="margin-top: 1rem"
      />
    </Card>

    <Loading v-if="loading"/>
    <PageEditor
      v-for="(page, pageIndex) in poll.pages" :key="pageIndex"
      :number="pageIndex + 1"
      v-model:page="poll.pages[pageIndex]"

      @addQuestion="() => addQuestion(pageIndex)"
      @addPage="() => addPage(pageIndex)"
      @remove="() => removePage(pageIndex)"
    >
      <QuestionEditor
        v-for="(question, questionIndex) in page.questions" :key="questionIndex"
        v-model:question="poll.pages[pageIndex].questions[questionIndex]"

        @remove="() => removeQuestion(pageIndex, questionIndex)"
        @copy="() => copyQuestion(pageIndex, questionIndex)"
      />
    </PageEditor>

    <Card
      title="Как увидят опрос респонденты"
      subtitle="Перед запуском посмотрите, как будет выглядеть опрос"
      style="background-color: var(--color-mute)"
    >
      <SecondaryButton
        text="Посмотреть демонстрацию"
        @click="async () => {
          await save()
          $router.push(`/demo/${$route.params.id}/0`)
        }"
      >
        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.67884 7.93165C2.09143 7.31094 2.9206 6.18152 4.0447 5.21477C5.17567 4.2421 6.52738 3.5 8.00001 3.5C9.47264 3.5 10.8244 4.2421 11.9553 5.21477C13.0794 6.18152 13.9086 7.31094 14.3212 7.93165C14.35 7.975 14.35 8.025 14.3212 8.06835C13.9086 8.68906 13.0794 9.81848 11.9553 10.7852C10.8244 11.7579 9.47264 12.5 8.00001 12.5C6.52738 12.5 5.17567 11.7579 4.0447 10.7852C2.9206 9.81848 2.09143 8.68906 1.67884 8.06835C1.65002 8.025 1.65002 7.975 1.67884 7.93165ZM8.00001 2C6.01865 2 4.32919 2.99167 3.06662 4.07751C1.79718 5.16926 0.880454 6.42307 0.429635 7.10129C0.0664231 7.64771 0.0664245 8.35229 0.429635 8.89871C0.880455 9.57693 1.79718 10.8307 3.06662 11.9225C4.32919 13.0083 6.01865 14 8.00001 14C9.98137 14 11.6708 13.0083 12.9334 11.9225C14.2028 10.8307 15.1196 9.57693 15.5704 8.89871C15.9336 8.35229 15.9336 7.64771 15.5704 7.10129C15.1196 6.42307 14.2028 5.16926 12.9334 4.07751C11.6708 2.99167 9.98137 2 8.00001 2ZM8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"/>
        </svg>
      </SecondaryButton>
    </Card>

    <BigBlueButton v-if="poll.info.status === 'draft'"
      text="Перейти к запуску"
      @click="$router.push(`/manage/${$route.params.id}/settings`)"
    />
  </div>
</template>

<style scoped lang="scss">

</style>