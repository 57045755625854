<script>
export default {
  name: "BigBlueButton",
  props: {
    text: {
      type: String,
      required: false
    }
  }
}
</script>

<template>
  <button class="button">
    <slot></slot>
    <span class="button__text" v-if="text">
      {{ text }}
    </span>
  </button>
</template>

<style scoped lang="scss">

.button{
  &:deep(svg){
    width: 1.25rem; height: 1.25rem;
    margin: 0.875rem 0 0.875rem 0;
    fill: var(--white);
    transition: all .1s;
  }
}

.button{
  height: 3.25rem;
  background-color: var(--blue);
  padding: 0 1rem;
  border-radius: 1rem;
  transition: all .1s;

  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;

  &__text{
    line-height: 3rem;
    font-size: 1rem;
    font-weight: 400;
    color: var(--white);
    transition: all .1s;
  }

  &:hover{
    filter: saturate(125%);
  }
}
</style>