<script>
import BigBlueButton from "@/components/ui/buttons/BigBlueButton.vue"

import { useWebApp } from 'vue-tg'
import {telegramLoginTemp} from "vue3-telegram-login"
import request from "@/request"
import {notify} from "@kyvg/vue3-notification"

export default {
  name: "Header",
  components: {BigBlueButton, telegramLoginTemp},
  data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    async newPoll() {
      const response = await request('polls/new')
      if (response) this.$router.push(`/manage/${response.poll_id}/edit`)
    },

    async login() {
      const response = await request('auth/login', {
        username: this.username,
        password: this.password
      })
      if (response) {
        window.localStorage.setItem("token", response.token)
        this.$store.dispatch('updateIsAuth')

        this.$router.go(0)
        this.$router.push('/home')

        notify({title: `Вы вошли как @${this.$store.getters.getUsername}`})
      }
    },
    async register() {
      await request('auth/register', {
        username: this.username,
        password: this.password
      })
    },

    async authCallback(data) {
      const response = await request('auth/telegram', { data })
      if (response) {
        window.localStorage.setItem("token", response.token)
        this.$store.dispatch('updateIsAuth')

        this.$router.go(0)
        this.$router.push('/home')

        notify({title: `Вы вошли как @${this.$store.getters.getUsername}`})
      }
    },
    async autoAuth(data) {
      const response = await request('auth/auto', { data })
      if (response) {
        window.localStorage.setItem("token", response.token)
        this.$store.dispatch('updateIsAuth')
      }
    },
  },
  beforeMount() {
    if (useWebApp().initData) {
      let decodedInitData = decodeURIComponent(useWebApp().initData)
      let params = new URLSearchParams(decodedInitData)
      let data = {}
      data.user = JSON.parse(params.get("user"))
      data.hash = params.get("hash")
      data.auth_date = params.get("auth_date")
      data.chat_instance = params.get("chat_instance")
      data.chat_type = params.get("chat_type")

      this.autoAuth(data)
    }
  }
}
</script>

<template>
  <div class="header" :class="{hidden: ['poll', 'demo', 'thank', 'finished'].includes($route.name)}">
    <router-link to="/home" class="header__logo">
      <img src="@/assets/logo-опросы.svg" alt="Polls">
    </router-link>

    <nav class="header__navigation" v-if="this.$store.getters.getIsAuth">
      <router-link to="/home" class="header__link" id="myPolls">
        Мои опросы
      </router-link>
      <div class="header__link" @click="newPoll">
        Создать опрос
      </div>
    </nav>

    <nav class="header__navigation" style="margin-left: auto">

      <!-- Временная регистрация -->
<!--      <Input type="text" style="width: 8rem" v-model="username"/>-->
<!--      <Input type="password" style="width: 8rem" v-model="password"/>-->
<!--      <button @click="login">log</button>-->
<!--      <button @click="register">reg</button>-->

      <!-- Профиль -->
      <p
        class="profile__username"
        v-if="$store.getters.getIsAuth"
      >
        @{{ $store.getters.getUsername }}({{ $store.getters.getPlan }})
      </p>
      <div
        class="profile__userpic"
        v-if="$store.getters.getUserpic"
      >
        <img :src="$store.getters.getUserpic" alt="" class="image">
      </div>

      <!-- Авторизация через Телеграм -->
      <telegramLoginTemp
        v-if="!$store.getters.getIsAuth"
        mode="callback"
        size="medium"
        telegram-login="PollsAuthBot"
        requestAccess="write"
        @callback="authCallback"
      />
    </nav>
  </div>
</template>

<style scoped lang="scss">

.header{
  width: 100%; height: 5rem;
  padding: 0 1.5rem;
  background-color: var(--color-background);

  position: fixed;
  top: 0; left: 0;

  display: flex;
  flex-direction: row;
  align-items: center;

  &__logo{
    height: 1.5rem;
    margin-right: 3rem;

    img{
      height: 100%;
    }
  }

  &__navigation{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
  }

  &__link{
    height: 2rem;
    padding: 0 1rem;
    border-radius: 1rem;
    background-color: var(--color-background);

    line-height: 2rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--color-text-default);

    white-space: nowrap;
    text-overflow: ellipsis;

    cursor: pointer;
    transition: all .1s;
    &:hover, &.router-link-active{
      background-color: var(--color-mute);
    }
  }

  .profile__username{
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--color-text-mute);
  }
  .profile__userpic{
    width: 2.5rem; height: 2.5rem;
    border-radius: 1.5rem;

    overflow: hidden;
    .image{
      width: 2rem; height: 2rem;
      margin: 0.25rem;
      border-radius: 1rem;
      background-color: var(--color-card);
    }
  }

  &.hidden .header__navigation:first-of-type{
    display: none;
  }
}

@media only screen and (orientation: portrait) and (max-device-width: 414px){
  .header{
    padding: 0 1rem;
  }
  .header__logo{
    height: 2rem;
    min-width: 3.75rem;
    max-width: 3.75rem;
    overflow: hidden;
    margin-right: 1rem;
  }
  #myPolls,
  .profile__username{
    display: none;
  }
}
</style>