<script>
import PollCard from "@/components/home/PollCard.vue"

import request from '@/request'
import {notify} from "@kyvg/vue3-notification"

export default {
  name: 'HomeView',
  components: {PollCard},
  data() {
    return {
      polls: [],
      loading: true
    }
  },
  methods: {
    async getPolls() {
      const polls = await request('polls/getPolls')
      if (polls) {
        this.polls = polls.reverse()
        this.loading = false
      }
    },
    async deletePoll(id) {
      request('polls/delete', { pollID: id })
      let index = this.polls.findIndex((poll) => poll.id === id)
      this.polls.splice(index, 1)
      notify({title: 'Опрос удален', type: 'success'})
    },
    async finishPoll(id) {
      request('polls/finish', { pollID: id })
      let index = this.polls.findIndex((poll) => poll.id === id)
      this.polls[index].status = 'finished'
      notify({title: 'Опрос завершён', type: 'success'})
    },
  },
  beforeMount() {
    this.getPolls()
  }
}
</script>

<template>
  <div class="home">
    <div class="home__header">
      <h1>Мои опросы</h1>
    </div>

    <Loading v-if="loading"/>
    <div class="polls">
      <PollCard
        v-for="poll in polls" :key="poll.id"
        :poll="poll"
        @delete="() => deletePoll(poll.id)"
        @finish="() => finishPoll(poll.id)"
      />
    </div>
  </div>
</template>

<style lang="scss">

.home{
  margin-bottom: 5rem;
}
.home__header{
  width: 100%; height: 3rem;
  margin-bottom: 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  h1{
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--color-text-default);
  }
}

.polls{
  width: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  grid-gap: 1.5rem;
}

@media only screen and (orientation: portrait) and (max-device-width: 414px){
  .home__header{
    padding: 0 0.5rem;
  }
  .polls{
    grid-template-columns: 1fr;
    grid-gap: 0.75rem;
  }
}
</style>