<script>

export default {
  name: "CheckboxOption",
  props: ['modelValue', 'name', 'disabled'],
  emits: ['update:model-value'],
}
</script>

<template>
  <label class="checkbox">
    <input
      type="checkbox"
      :checked="modelValue"
      @click="$emit('update:model-value', $event.target.checked)"
      :name="name"
      :disabled="disabled"
    >
    <div class="checkbox__square">
      <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.78 4.21995C13.9204 4.36057 13.9993 4.5512 13.9993 4.74995C13.9993 4.9487 13.9204 5.13932 13.78 5.27995L6.53 12.53C6.38937 12.6704 6.19875 12.7493 6 12.7493C5.80125 12.7493 5.61062 12.6704 5.47 12.53L2.22 9.27995C2.08752 9.13777 2.0154 8.94973 2.01882 8.75542C2.02225 8.56112 2.10096 8.37574 2.23838 8.23832C2.37579 8.10091 2.56118 8.0222 2.75548 8.01877C2.94978 8.01534 3.13782 8.08747 3.28 8.21995L6 10.9399L12.72 4.21995C12.8606 4.0795 13.0512 4.00061 13.25 4.00061C13.4487 4.00061 13.6394 4.0795 13.78 4.21995Z"/>
      </svg>
    </div>
    <slot></slot>
  </label>
</template>

<style scoped lang="scss">

.checkbox{
  width: 100%; height: 2.75rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 1rem;
  font-weight: 400;
  color: var(--color-text-default);
  line-height: 2.75rem;

  input {
    display: none;
  }

  &__square {
    min-width: 1.25rem; height: 1.25rem;
    padding: 0.125rem; margin: 0.75rem;
    border-radius: 0.25rem;
    background-color: var(--color-mute);
    line-height: 1rem;

    svg {
      width: 1rem; height: 1rem;
      fill: var(--color-mute);
    }
  }

  &:has(input:checked) .checkbox__square{
    background-color: var(--blue);
    svg{
      fill: var(--white);
    }
  }

  &:deep(button){
    display: none;
  }
  &:hover:deep(button){
    display: block;
  }
}

</style>