<script>
import Page from "@/components/summary/Page.vue"
import QuestionResult from "@/components/summary/QuestionResult.vue"

import request from "@/request"

export default {
  name: "SummaryView",
  components: {QuestionResult, Page},
  data() {
    return {
      loading: true,
      info: {
        title: ''
      },
      result: {
        pages: []
      }
    }
  },
  methods: {
    async getInfo() {
      const response = await request('polls/getInfo', { id: this.$route.params.id })
      if (response) {
        if (response.status === 'draft') {
          this.$router.push('/home')
        }
        else {
          this.info = response
          await this.getSummary()
        }
      }
    },
    async getSummary() {
      const response = await request('polls/getSummary', { pollID: this.$route.params.id })
      if (response) {
        this.result = response
        this.loading = false
      }
    }
  },
  beforeMount() {
    this.getInfo()
  }
}
</script>

<template>
  <div class="column">
    <Loading v-if="loading"/>
    <Page
      v-for="page in result.pages"
      :title="page.title"
    >
      <QuestionResult
        v-for="question in page.questions"
        :question="question"
      />
    </Page>
  </div>
</template>

<style scoped lang="scss">

</style>