<script>
import Pricing from "@/components/landing/Pricing.vue"

export default {
  name: "LandingView",
  components: {Pricing}
}
</script>

<template>
  <div class="landing">
    <h1 class="title">Доступ навсегда</h1>
    <Pricing />
  </div>
</template>

<style scoped lang="scss">

.title{
  font-size: 2.5rem;
  font-weight: 600;
  margin: 1.5rem 0;
}
</style>